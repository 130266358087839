import * as React from 'react';
import { string, bool } from 'prop-types';
import styled from 'styled-components';
import { ChevronRight } from '@core-ds/icons/16';
import { color, fontSize, space } from '@dozuki/web-js/primitives';

const maxBreadcrumbLength = 18;

const BreadcrumbText = styled.span`
   margin: 0 ${space[2]};
   font-size: ${fontSize.md};
   font-weight: normal;
   color: ${({ isLeaf }) => (isLeaf ? color.gray[800] : color.gray[500])};
   transition: all 0.15s ease-in-out;
   white-space: nowrap;
   text-overflow: ellipsis;
   overflow: hidden;
   width: 100%;
`;

const BreadcrumbLink = styled.a`
   display: flex;
   align-items: center;
   text-decoration: none !important;
   flex-basis: ${({ title, variableWidth }) =>
      title.length > maxBreadcrumbLength && !variableWidth ? '140px' : 'auto'};
   flex-shrink: 1;
   flex-grow: ${({ variableWidth }) => (variableWidth ? '1' : '0')};
   overflow: hidden;

   &:hover ${BreadcrumbText} {
      color: ${color.blue[500]};
   }
`;

const ArrowContainer = styled.span`
   height: ${space[4]};
   width: ${space[4]};
`;

const Breadcrumb = ({ title, url, isLeaf, hideArrow, variableWidth = false, testid = null }) => {
   return (
      <BreadcrumbLink
         data-testid={testid}
         href={url}
         title={title}
         variableWidth={variableWidth}
         alt={title}
      >
         {hideArrow || (
            <ArrowContainer>
               <ChevronRight color={color.gray[400]} />
            </ArrowContainer>
         )}
         <BreadcrumbText isLeaf={isLeaf} variableWidth={variableWidth}>
            {title}
         </BreadcrumbText>
      </BreadcrumbLink>
   );
};

Breadcrumb.propTypes = {
   title: string.isRequired,
   url: string.isRequired,
   hideArrow: bool.isRequired,
   isLeaf: bool.isRequired,
   testid: string,
   variableWidth: bool,
};

export default Breadcrumb;
