import * as React from 'react';
import { Component } from 'react';
import ReactDOM from 'react-dom';
import { array } from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import { transition, fontSize, space, color } from '@dozuki/web-js/primitives';
import { dropdownOpen, dropdownClose } from 'Shared/animations';

const timeout = 140;

const DropdownButtonContainer = styled.div`
   flex-basis: ${space[7]};
   flex-shrink: 0;
   flex-grow: 0;
`;

const Button = styled.div`
   display: flex;
   align-items: center;
   height: 50px;
   padding: 0 ${space[2]};
   cursor: pointer;

   &:hover {
      color: ${color.blue[700]};
   }
`;

const A = styled.a`
   &:hover {
      text-decoration: none;
   }
`;

const Dropdown = styled.div`
   z-index: 4;
   position: absolute;
   display: ${props => (props.isOpen ? 'flex' : 'none')};
   flex-direction: column;
   top: ${space[7]};
   left: ${space[4]};
   min-width: 160px;
   max-width: calc(100% - ${space[6]});
   background: ${color.white};
   border: 1px solid ${color.gray[200]};
   border-radius: 4px;
   box-shadow: 0 15px 30px rgba(17, 22, 26, 0.2);
   animation: ${props => (props.isClosing ? dropdownClose : dropdownOpen)} 150ms;
`;

const DropdownItem = styled.h3`
   margin: 0;
   padding: ${space[4]};
   color: ${color.black};
   font-size: ${fontSize.md};
   font-weight: normal;
   transition: background-color ${transition[100]};
   max-width: 100%;

   &:hover {
      background-color: ${color.gray[100]};
   }
`;

class OverflowDropdown extends Component {
   state = {
      isOpen: false,
      isClosing: false,
   };

   toggleDropdown = () => {
      if (this.state.isOpen) {
         this.animateClosed();
      } else {
         this.setState({ isOpen: !this.state.isClosing });
      }
   };

   animateClosed() {
      this.setState({
         isOpen: false,
         isClosing: true,
      });

      setTimeout(() => {
         this.setState({ isClosing: false });
      }, timeout);
   }

   // Closes the dropdown if anywhere outside the dropdown is clicked.
   handleOutsideClick = event => {
      if (!this.state.isOpen) {
         return;
      }

      const domNode = ReactDOM.findDOMNode(this);

      if (!(domNode && domNode.contains(event.target))) {
         this.animateClosed();
      }
   };

   componentDidMount() {
      document.addEventListener('click', this.handleOutsideClick, true);
   }

   componentWillUnmount() {
      document.removeEventListener('click', this.handleOutsideClick, true);
   }

   render() {
      const { items } = this.props;

      return (
         <DropdownButtonContainer>
            <Button onClick={this.toggleDropdown}>
               <FontAwesomeIcon icon={faEllipsis} color={color.gray[600]} />
            </Button>
            <Dropdown
               isOpen={this.state.isOpen || this.state.isClosing}
               isClosing={this.state.isClosing}
            >
               {items.map((item, index) => (
                  <A key={index} textDecoration="none !important" href={item.url}>
                     <DropdownItem>{item.title}</DropdownItem>
                  </A>
               ))}
            </Dropdown>
         </DropdownButtonContainer>
      );
   }
}

OverflowDropdown.propTypes = {
   items: array,
};

OverflowDropdown.defaultProps = {
   items: [],
};

export default OverflowDropdown;
