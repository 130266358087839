import * as React from 'react';
import initializeComponent from 'Shared/react-initialize';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { space } from '@dozuki/web-js/primitives';
import { withBreakpointsContext, BreakpointsContext } from 'core-components';
import Breadcrumb from './breadcrumb';
import OverflowDropdown from './overflow_dropdown';

const MOBILE_CRUMB_NUM = 0;
const DESKTOP_CRUMB_NUM = 3;

const BreadcrumbContainer = styled.div`
   display: flex;
   height: 100%;
   padding-left: ${space[3]};
   max-width: 640px;
`;

const NavBreadcrumbs = ({ breadcrumbs, showFullTitle }) => {
   const breakpoints = React.useContext(BreakpointsContext);

   if (!breadcrumbs) {
      return null;
   }

   const numCrumbs = breakpoints.isTabletDesktop() ? DESKTOP_CRUMB_NUM : MOBILE_CRUMB_NUM;

   const hasOverflow = breadcrumbs && breadcrumbs.length >= numCrumbs;
   const overflowItems = hasOverflow ? breadcrumbs.slice(0, breadcrumbs.length - numCrumbs) : [];
   const overflowLength = overflowItems.length;
   const startPos = breadcrumbs.length - numCrumbs;
   const lastIndex = overflowLength < 1 ? breadcrumbs.length - 1 : numCrumbs - 1;

   return (
      <BreadcrumbContainer breadcrumbs-testid="breadcrumb-container">
         {hasOverflow && overflowLength >= 1 && (
            <OverflowDropdown items={overflowItems.reverse()} />
         )}
         {breadcrumbs.length > 1 &&
            breadcrumbs
               .slice(startPos >= 0 && overflowLength >= 1 ? startPos : 0)
               .map((breadcrumb, index) => (
                  <Breadcrumb
                     key={breadcrumb.title}
                     url={breadcrumb.url}
                     title={breadcrumb.title}
                     hideArrow={index === 0 && (!hasOverflow || overflowLength < 1)}
                     isLeaf={index === lastIndex}
                     variableWidth={showFullTitle && index === lastIndex && breakpoints.isDesktop()}
                     testid={`breadcrumb-${index}`}
                  />
               ))}
      </BreadcrumbContainer>
   );
};

NavBreadcrumbs.propTypes = {
   breadcrumbs: PropTypes.array.isRequired,
};

initializeComponent('NavBreadcrumbs', withBreakpointsContext(NavBreadcrumbs));
